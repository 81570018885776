'use client';

import {
  Navbar as NextUINavbar,
  NavbarContent,
  NavbarBrand,
  NavbarMenuToggle,
  NavbarMenu,
  NavbarMenuItem,
} from '@nextui-org/navbar';
import { ReactNode, useState } from 'react';
import { LogoWithText } from '@/components/icons';
import NextLink from 'next/link';
import { Link } from '@nextui-org/link';
import Image from 'next/image';

const getMenuDescription = (label: string) => {
  const descriptions: { [key: string]: string } = {
    会社概要: 'CoPaletteについて詳しく知る',
    私たちの軸: '私たちが大切にしている価値観',
    ニュース: '最新の活動やお知らせ',
    採用情報: '一緒に働く仲間を募集中',
    お問い合わせ: 'ご質問・ご相談はこちら',
  };

  return (
    <span className="text-sm text-neutral-500">{descriptions[label]}</span>
  );
};

export const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const menuItems = [
    { href: '/company', label: '会社概要' },
    { href: '/axis', label: '私たちの軸' },
    { href: '/news', label: 'ニュース' },
    {
      href: 'https://copalette-app.notion.site/CoPalette-be90d79ce2214d5d8d1f4a9829826c3d?pvs=4',
      label: '採用情報',
      isExternal: true,
    },
    { href: '/contact', label: 'お問い合わせ' },
  ];

  const handleLinkClick = () => {
    setIsMenuOpen(false);
  };

  return (
    <NextUINavbar
      maxWidth="xl"
      position="sticky"
      isMenuOpen={isMenuOpen}
      onMenuOpenChange={setIsMenuOpen}
    >
      <NavbarContent className="md:hidden" justify="start">
        <NavbarMenuToggle
          aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
        />
      </NavbarContent>

      <NavbarContent className="md:hidden pr-3" justify="center">
        <NavbarBrand>
          <NextLink href="/">
            <Image
              src="/image/logo.png"
              alt="logo black"
              width={48}
              height={48}
            />
          </NextLink>
        </NavbarBrand>
      </NavbarContent>

      <NavbarContent className="hidden md:flex" justify="start">
        <NavbarBrand as="li">
          <NextLink className="flex items-center justify-start gap-1" href="/">
            <LogoWithText />
          </NextLink>
        </NavbarBrand>
      </NavbarContent>

      <NavbarContent className="hidden md:flex" justify="end">
        <ul className="gap-5 lg:gap-10 flex">
          {menuItems.map((item, index) => (
            <Link
              key={index}
              href={item.href}
              isExternal={item.isExternal}
              showAnchorIcon={item.isExternal}
              anchorIcon={
                item.isExternal ? (
                  <i className="ri-external-link-line text-black pl-1" />
                ) : undefined
              }
            >
              <button className="font-bold text-black text-sm lg:text-md text-nowrap">
                {item.label}
              </button>
            </Link>
          ))}
        </ul>
      </NavbarContent>

      <NavbarMenu className="pt-10 px-6">
        {menuItems.map((item, index) => (
          <NavbarMenuItem
            key={`${item.label}-${index}`}
            className="py-4 border-b border-neutral-300 last:border-none"
          >
            <Link
              href={item.href}
              className="w-full flex items-center justify-between group"
              isExternal={item.isExternal}
              onClick={handleLinkClick}
            >
              <div className="flex flex-col gap-1">
                <span className="text-xl font-bold text-neutral-800">
                  {item.label}
                </span>
                {getMenuDescription(item.label)}
              </div>
              <div className="w-10 h-10 rounded-full flex items-center justify-center text-black group-hover:bg-neutral-100 group-hover:scale-105 transition-all duration-300 ease-out">
                {item.isExternal ? (
                  <i className="ri-external-link-line text-lg transition-transform duration-300 group-hover:translate-x-0.5 group-hover:-translate-y-0.5" />
                ) : (
                  <i className="ri-arrow-right-line text-lg transition-transform duration-300 group-hover:translate-x-0.5" />
                )}
              </div>
            </Link>
          </NavbarMenuItem>
        ))}
      </NavbarMenu>
    </NextUINavbar>
  );
};
